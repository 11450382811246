@import "utilities/variables";
@import "mixins/fontSize";
@import "mixins/fonts";
@import "mixins/flex";
@import "mixins/buttons";


.footerWrap {
    background: var(--bg-primary);

    .footerContainer {
        .content {
            padding: 48px 15px;

            .mainLinks {
                margin-bottom: 18px;

                .mainLinksList {
                    .mainLinkItem {
                        p {
                            @include createFontSemiBold($fs-p3, var(--clr-secondary));
                            text-transform: uppercase;
                            cursor: pointer;
                            display: inline-block;
                            width: auto;
                        }

                        p:hover {
                            color: var(--clr-primary);
                            transition: 1s;
                        }
                    }

                    .mainLinkItem:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }

            .otherLinks {
                margin-bottom: 18px;

                .otherLinksList {
                    .otherLinkItem {
                        p {
                            @include createFontNormal($fs-p3, var(--clr-secondary));
                            text-transform: capitalize;
                            cursor: pointer;
                            display: inline-block;
                            width: auto;
                        }

                        p:hover {
                            color: var(--clr-primary);
                            transition: 1s;
                        }
                    }



                    .otherLinkItem:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }

            .footerText {
                text-align: center;

                .copyright {
                    @include createFontNormal(15px, var(--text-faded));

                    span {
                        @include createFontSemiBold(15px, var(--text-faded));
                    }
                }

                .brand {
                    @include createFontSemiBold(15px, var(--text-faded));
                }
            }
        }
    }
}

@media screen and (min-width:768px) {
    .footerWrap {
        .footerContainer {
            .content {
                .mainLinks {
                    margin-bottom: 5px;

                    .mainLinksList {
                        display: flex;
                        gap: 18px;

                        .mainLinkItem:not(:last-child) {
                            margin-bottom: 0px;
                        }
                    }
                }

                .otherLinks {
                    .otherLinksList {
                        display: flex;
                        gap: 18px;
                    }
                }

                .footerText {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}