@import "utilities/variables";
@import "mixins/fontSize";
@import "mixins/fonts";
@import "mixins/flex";
@import "mixins/buttons";

.bannerWrap {
    border-bottom: 1px solid var(--border);

    .bannerContainer {
        .content {
            padding: 38px 15px;

            .colInfo {
                text-align: center;

                .colInfoHeader {
                    @include createFontSemiBold($fs-h4, var(--clr-secondary));
                }

                .colInfoSubheader {
                    @include createFontNormal($fs-h6, var(--clr-secondary));
                    padding-top: 16px;
                }

                .colInfoImg {
                    position: relative;
                    width: calc(100vw - 40vw);
                    height: 120px;
                    margin: 15px auto;
                }
            }

            .colForm {
                box-shadow: 0 2px 13px #e8eaec;
                padding: 34px 15px;
                border: 2px solid var(--border);
                border-radius: 8px;

                .colFormHeader {
                    @include createFontNormal($fs-h6, var(--clr-secondary));
                    padding-bottom: 18px;
                }

                .colFormBtn {
                    padding: 14px 20px;
                    cursor: pointer;
                    background: var(--clr-primary);
                    border-radius: 8px;
                    border: none;
                    width: 100%;
                    @include createFontNormal($fs-p3, var(--text-white));
                }

                .formHelper {
                    margin-top: 28px;

                    h4 {
                        @include createFontSemiBold($fs-p3, var(--clr-secondary));
                    }

                    p {
                        @include createFontNormal($fs-p3, var(--text-faded));
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:768px) {
    .bannerWrap {
        .bannerContainer {
            .content {
                max-width: 430px;
                margin: 0 auto;

                .colInfo {
                    .colInfoImg {
                        width: 280px;
                        height: 150px;
                        margin: 40px auto;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:991px) {
    .bannerWrap {
        .bannerContainer {
            .content {
                padding: 64px 15px;
                display: flex;
                align-items: center;
                max-width: 100%;
                gap: 40px;

                .colInfo {
                    text-align: left;
                    width: 50%;

                    .colInfoHeader {
                        @include createFontSemiBold($fs-h2, var(--clr-secondary));
                    }

                    .colInfoImg {
                        width: 80%;
                        height: 190px;
                        margin: 40px 0px;
                    }
                }

                .colForm {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (min-width:1200px) {
    .bannerWrap {
        .bannerContainer {
            .content {
                .colInfo {
                    .colInfoHeader {
                        @include createFontSemiBold($fs-h1, var(--clr-secondary));
                        line-height: 50px;
                    }
                }
            }
        }
    }
}