/* larger than heading font sizes */
$fs-giga: 80px;
$fs-mega: 70px;
$fs-kilo: 60px;

$fs-lg: 50px;

/* heading font sizes */
$fs-h1: 36px;
$fs-h2: 32px;
$fs-h3: 28px;
$fs-h4: 24px;
$fs-h5: 20px;
$fs-h6: 18px;

/* paragraph font sizes */
$fs-p3: 16px;
$fs-p2: 15px;
$fs-p1: 14px;

/* smaller than heading font sizes */
$fs-milli: 12px;
$fs-micro: 10px;
$fs-nano: 8px;