@import "fonts";

@mixin setFontSize($size) {
  font-size: $size;
  line-height: $size * 1.5;
}

@mixin createFontNormal($size, $color) {
  @include fontPrimaryNormal();
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}

@mixin createFontSemiBold($size, $color) {
  @include fontPrimarySemiBold();
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}

@mixin createFontBold($size, $color) {
  @include fontPrimaryBold();
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}

@mixin createFontExtraBold($size, $color) {
  @include fontPrimaryExtraBold();
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}