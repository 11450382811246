@import "utilities/variables";
@import "mixins/fontSize";
@import "mixins/fonts";
@import "mixins/flex";
@import "mixins/buttons";


.statusWrap {
    background: var(--bg-primary);
    .statusContainer {
        .content {
            padding: 38px 15px;

            h2 {
                @include createFontSemiBold($fs-h5, var(--clr-secondary));
                text-align: center;
            }

            .statusList {
                margin-top: 28px;

                .ListItem {
                    text-align: center;
                    border: 1px solid var(--border);
                    border-radius: 4px;
                    padding: 18px 14px;

                    .statusListIcon {
                        position: relative;
                        width: 40px;
                        height: 40px;
                        margin: 0 auto 8px auto;
                    }

                    h3 {
                        @include createFontSemiBold($fs-p3, var(--clr-secondary));
                        padding-bottom: 6px;
                    }

                    p {
                        @include createFontNormal($fs-p3, var(--text-faded));
                    }
                }

                .ListItem:not(:last-child) {
                    margin-bottom: 28px;
                }
            }
        }
    }
}

@media screen and (min-width:768px) {
    .statusWrap {
        .statusContainer {
            .content {
                .statusList {
                    max-width: 430px;
                    margin: 28px auto 0px auto;
                }
            }
        }
    }
}

@media screen and (min-width:991px) {
    .statusWrap {
        .statusContainer {
            .content {
                h2 {
                    @include createFontSemiBold($fs-h3, var(--clr-secondary));
                    text-align: center;
                }
                padding: 64px 15px;
                .statusList {
                    max-width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 48px;

                    .ListItem {
                        width: 18%;
                    }
                    .ListItem:not(:last-child){
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}