@import "utilities/variables";
@import "mixins/fontSize";
@import "mixins/fonts";
@import "mixins/flex";
@import "mixins/buttons";

.faqWrap {
    border-top: 1px solid var(--border);
    .faqContainer {
        padding: 38px 15px;
        .header {
            @include createFontSemiBold($fs-h5, var(--clr-secondary));
            text-align: center;
        }


        .transition,
        .list .listItem .listItemIcon:before,
        .list .listItem .listItemIcon:after,
        .text {
            transition: all 0.25s ease-in-out;
            @include createFontNormal($fs-p3,var(--clr-secondary));
            margin-top: 12px;
        }



        .list {
            list-style: none;
            perspective: 900;
            padding: 0;
            margin: 0;
            margin-top: 28px;
        }

        .list .listItem {
            position: relative;
            padding: 0;
            margin: 0;
            border-top: 1px dotted #dce7eb;
            padding: 8px 0px;
        }

        .list .listItem:nth-of-type(1) {
            animation-delay: 0.5s;
        }

        .list .listItem:nth-of-type(2) {
            animation-delay: 0.75s;
        }

        .list .listItem:nth-of-type(3) {
            animation-delay: 1s;
        }

        .list .listItem:last-of-type {
            padding-bottom: 0;
        }

        .list .listItem i {
            position: absolute;
            transform: translate(-6px, 0);
            margin-top: -3px;
            right: 0;
        }

        .list .listItem h2 {
            @include createFontNormal($fs-p3, var(--clr-secondary))
        }

        .list .listItem i:before,
        .list .listItem i:after {
            content: "";
            position: absolute;
            background-color: var(--clr-secondary);
            width: 3px;
            height: 9px;
        }

        .list .listItem i:before {
            transform: translate(-2px, 0) rotate(45deg);
        }

        .list .listItem i:after {
            transform: translate(2px, 0) rotate(-45deg);
        }

        .list .listItem input[type=checkbox] {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
        }

        .list .listItem input[type=checkbox]:checked~p {
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            transform: translate(0, 50%);
        }

        .list .listItem input[type=checkbox]:checked~i:before {
            transform: translate(2px, 0) rotate(45deg);
        }

        .list .listItem input[type=checkbox]:checked~i:after {
            transform: translate(-2px, 0) rotate(-45deg);
        }

        @keyframes flipdown {
            0% {
                opacity: 0;
                transform-origin: top center;
                transform: rotateX(-90deg);
            }

            5% {
                opacity: 1;
            }

            80% {
                transform: rotateX(8deg);
            }

            83% {
                transform: rotateX(6deg);
            }

            92% {
                transform: rotateX(-3deg);
            }

            100% {
                transform-origin: top center;
                transform: rotateX(0deg);
            }
        }
    }
}
@media screen and (min-width:768px) {
    .faqWrap {
        .faqContainer {

            .list{
                max-width: 430px;
                margin: 28px auto 0px auto;
            }
        }
    }
}
@media screen and (min-width:991px) {
    .faqWrap {
        .faqContainer {
            padding: 64px 15px;

            .header{
                @include createFontSemiBold($fs-h3, var(--clr-secondary));
            }
            .list{
                margin: 48px auto 0px auto;
            }
            .list .listItem h2 {
                @include createFontNormal($fs-h5, var(--clr-secondary))
            }
    
        }
    }
}