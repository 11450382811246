@mixin fontPrimaryNormal() {
  font-family: "Kanit-Light", sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin fontPrimarySemiBold() {
  font-family: "Kanit-SemiBold", sans-serif;
  font-style: normal;
  font-weight: 600;
}

@mixin fontPrimaryBold() {
  font-family: "Kanit-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin fontPrimaryExtraBold() {
  font-family: "Kanit-ExtraBold", sans-serif;
  font-style: normal;
  font-weight: 800;
}
